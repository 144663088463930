import React, { useEffect, useState, useImperativeHandle, forwardRef } from 'react'
import { Card, CardBody, Button, Spinner } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import BootstrapTable from 'react-bootstrap-table-next';
import { requestGetWithKey, dowloadFilePDF } from '../../helpers/utils';
import { DateRangePicker } from 'react-dates';
import Typed from "react-typed"
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import "react-datetime/css/react-datetime.css";
import moment from 'moment';


const ProductsSummaryCard = forwardRef(({
  selectedCampaign, startDate, endDate
}, ref) => {
  const [focusedInput, setFocusedInput] = useState(null);
  const [products, setProducts] = useState(null);
  const [startDateFormatChange, setStartDateFormatChange] = useState(null)
  const [endDateFormatChange, setEndDateFormatChange] = useState(null)
  const [loading, setLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState(false)
  const [loadingMessageLong, setLoadingMessageLong] = useState(false);
  const [loadingMessageTooLong, setLoadingMessageTooLong] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null)
  const [emptyMessage, setEmptyMessage] = useState(false)
  const [isProductSummaryLabelVisible, setIsProductSummaryLabelVisible] = useState(false);
  const [productSummaryLabelText, setProductSummaryLabelText] = useState('');
  
  const groupBy = (arr, property) => {
    return arr.reduce(function (memo, x) {
      if (!memo[x[property]]) { memo[x[property]] = []; }
      memo[x[property]].push(x);
      return memo;
    }, {});
  }

  const getHighlight = (data) => {
    return data.reduce((acc, shot) => {
      if (shot.Gross_Sales) {
        acc = acc.Gross_Sales > shot.Gross_Sales ? acc : shot;
        return acc
      } return acc
    }, 0);
  };

  const fetchProductSummaryData = (selectedCampaign, start, end) => {
    if (selectedCampaign) {
      setLoading(true)
      setErrorMessage(false)
      setLoadingMessageTooLong(false)
      setLoadingMessage(false)
      setIsProductSummaryLabelVisible(false);
      let url = `api/zoho/campaigns/${selectedCampaign?.value}/products`;
      if (start && end) {
        url += `?start=${startDateFormatChange}&end=${endDateFormatChange}`;
      }
      let getProducts = [url];
      requestGetWithKey(getProducts)
        .then(res => {
          let result = res.message.data;
          let newData = [];
          const dataGroup = groupBy(result, 'Product_Category');
          for (let key in dataGroup) {
            dataGroup[key].map((itemName) => {
              if (itemName.Layout.name === 'Product Offering') {
                itemName.Invoice_Name = itemName.Invoice_Name
              } else if (itemName.Invoice_Name === null) {
                itemName.Invoice_Name = itemName.Product_Name
              }
            })
            let getLargeAmt = getHighlight(dataGroup[key]);
            if (getLargeAmt) {
              let newDataGroup = dataGroup[key].map((_item) => {
                if (_item.id == getLargeAmt.id) {
                  _item.large = true;
                  return _item
                } else {
                  return _item
                }
              })
              newData.push(...newDataGroup)
            } else {
              newData.push(...dataGroup[key])
            }
          }
          setProducts(newData);
          setLoading(false);
          if (start && end) {
            showProductSummaryLabel();
          } else {
            setIsProductSummaryLabelVisible(false);
          }
        })
        .catch((error) => {
          console.log(error)
          if (error === 404) {
            setEmptyMessage(true)
            setErrorMessage(null)
          }
          if (error.status === false) {
            console.log("text")
            setErrorMessage(error.data.msg)
            setEmptyMessage(false)
          }
          setLoading(false)
          setIsProductSummaryLabelVisible(false);
        })
    }
  };

  useImperativeHandle(ref, () => ({
    fetchProductSummaryData,
  }));

  useEffect(() => {
    if (selectedCampaign && selectedCampaign?.value) {
      fetchProductSummaryData(selectedCampaign, startDate, endDate);
    }
  }, [selectedCampaign]);

  useEffect(() => {
    if(startDate) {
      let startDateChg = moment(startDate, "DD-MM-YYYY");
      setStartDateFormatChange(startDateChg.format('YYYY-MM-DD'))
    } else {
      setStartDateFormatChange(null)
    }
  }, [startDate])

  useEffect(() => {
    if(endDate) {
      let endDateChg = moment(endDate, "DD-MM-YYYY");
      setEndDateFormatChange(endDateChg.format('YYYY-MM-DD'))
    } else {
      setEndDateFormatChange(null)
    }
  }, [endDate])
  
  const showProductSummaryLabel = () => {
    setProductSummaryLabelText(`The product summary and campaign sales report export will reflect your selected date range of ${moment(startDate).format('DD/MM/YYYY')} - ${moment(endDate).format('DD/MM/YYYY')}`);
    setIsProductSummaryLabelVisible(true);
  };

  const loadMessage = () => {
    setTimeout(() => {
      setLoadingMessage(true)
      setLoadingMessageTooLong(false)
      setLoadingMessageLong(false)
    }, 0);
    setTimeout(() => {
      setLoadingMessageLong(true)
      setLoadingMessage(false)
      setLoadingMessageTooLong(false)
    }, 20000);
  }
  const exportList = () => {
    setLoadingMessageTooLong(false)
    setLoading(true)
    loadMessage()
    let searchQuery = ''
    if (startDateFormatChange && endDateFormatChange) {
      searchQuery = `?&start=${startDateFormatChange}&end=${endDateFormatChange}`
    }
    let getProductsDownload = [`api/zoho/products/download${searchQuery}`]
    if (selectedCampaign) {
      getProductsDownload = [`api/zoho/campaigns/${selectedCampaign?.value}/products/download${searchQuery}`]
    }
    dowloadFilePDF(getProductsDownload)
      
      .then((response) => {
        setLoading(false), setLoadingMessageTooLong(false),
        setLoadingMessageLong(false),
        setLoadingMessage(false)
      })
      .catch((error) => {
        if (error === 503) {
          setLoadingMessageTooLong(true)
        }
        if (error === 404) {
          setEmptyMessage(true)
          setErrorMessage(null)
        }
        if (error.statusCode === 500) {
          setErrorMessage(error.body.data.msg)
          setEmptyMessage(false)
        }
        setErrorMessage(error.data.msg)
        setLoading(false),
        setLoadingMessageLong(false), 
        setLoadingMessage(false)
      })
      
  }


  //This applies styles to product summary records
  const customRowStyle = (row, cell) => {
    if (row.large && row.Qty_in_Stock < 30) {
      return `bs-greenColor bs-redColor`
    }
    else if (row.large) {
      return `bs-greenColor`
    }
    else if (row.Qty_in_Stock < 30) {
      return `bs-redColor`
    }
    return `bs-table-row`;
  }
  const priceFormatter = (cell) => {
    return cell?.toLocaleString('en-AU', {
      style: 'currency',
      currency: 'AUD',
    })
  }


  return (
    selectedCampaign && <Card className="border-bottom mb-3">
      <CardBody className="overflow-hidden p-lg-4 card-inside" style={{ height: 'unset', minHeight: '55vh' }}>
        <div className="row">
          <div className="col-8">
            <h1 className="font-weight-semi-bold" style={{ fontSize: '20px', color: 'rgb(19, 19, 20)' }}>Product summary</h1>
              <h4 className="product-summary-label" style={{ fontSize: '12px', color: '#626468', marginTop: '-8px', display: isProductSummaryLabelVisible ? 'block' : 'none' }}>
                {productSummaryLabelText}
              </h4>
          </div>
          <div className="col-4">
            <div className="text-right mb-4">
              <div className="d-inline-block">
                <Button
                  id="productSummaryCardExportBtn"
                  className="ml-auto px-5 btn-vino btn-vino-primary"
                  transform="down-1 shrink-4"
                  style={{ width: 317 }}
                  onClick={() => exportList()}
                  disabled={false}
                >
                  Export Campaign Sales report
                </Button>
              </div>
            </div>
          </div>
        </div>
        {!emptyMessage ?
          !loadingMessageTooLong ? (
            !loading && !errorMessage && products && products.length > 0 &&
          <>
          <BootstrapTable
            id="productSummaryCardTable"
            bootstrap4
            keyField="id"
            data={products}
            headerClasses="bs-table-header"
            rowClasses={customRowStyle}
            columns={[{
              dataField: 'Product_Category',
              text: 'Category',
              sort: true
            }, {
              dataField:'Invoice_Name',
              text: 'Name',
              sort: true
            }, {
              dataField: 'Quantity_Sold',
              text: 'Qty sold',
              type: 'number',
              sort: true
            }, {
              dataField: 'Qty_in_Stock',
              text: 'Qty remaining',
              type: 'number',
              sort: true
            }, {
              dataField: 'Gross_Sales',
              text: 'Gross sales**',
              type: 'number',
              sort: true,
              formatter: priceFormatter
            }, {
              isDummyField: true,
              dataField: 'actions',
              text: 'Actions',
              sort: false,
              style: { width: 200 },
              formatter: ((cell, row, index) =>
                <a href={`mailto:campaign.managers@brunchagency.com.au?subject=${selectedCampaign.wineryName}%20-%20${selectedCampaign.label}:%20Change%20Request%20for%20${row.Product_Name}&body=Hi%20Team%0D%0DPlease%20make%20the%20following%20alterations%20for%20${row.Product_Name}%20in%20${selectedCampaign.label}:%0D%0D%0DKind%20regards,%0D${selectedCampaign.ownerName}%0D${selectedCampaign.wineryName}`}>
                  <Button
                    className="ml-auto px-2 btn-vino btn-vino-primary"
                    transform="down-1 shrink-4"
                    id={"change" + index}
                    style={{ width: 200 }}
                  >
                    Send email request
                  </Button>
                </a>
              ),
              attrs: (() => { return { title: 'This will open your default email program' } }),
              headerAttrs: (() => { return { title: 'This will open your default email program' } })
            }]}
            defaultSorted={[{
              dataField: 'Gross_Sales',
              order: 'desc'
            }]}
            defaultSortDirection="asc"
          />
          <div className="font-weight-normal" style={{ fontSize: '12px' }}>
          *: This pre-mixed box consumes the inventory at the product level (from the products included in the box).
          <br></br>
          **: Gross sales includes product refunds and adjustments and excludes invoice level adjustments.
        </div>
            </>
        ):
          <p className="loadingText">
            <span>Looks like the server has run out of patience and is having a time-out. Take a deep breath and try again. But if you see this a couple of times make a screenshot and send us an email because it’s probably more serious than just a grumpy or busy server
            </span>
            </p>
          :
          <div className="text-center" id="productSummaryCardMessage2">
            <FontAwesomeIcon icon={faSearch} className="fs-2 mb-2" />
            <h1 className="font-weight-bold mb-2 fs-3">Oops, we didn't find anything</h1>
            <p className="mb-1 fs-1">Try selecting a different date</p>
          </div>
        }
        {!loading && !products && !errorMessage && !emptyMessage &&
          <div className="text-center" id="productSummaryCardMessage1">
            <FontAwesomeIcon icon={faSearch} className="fs-2 mb-2" />
            <h1 className="font-weight-bold mb-2 fs-3">What can we get for you?</h1>
            <p className="mb-1 fs-1">Try selecting a date</p>
          </div>}
        {!loading && !errorMessage && products && products.length === 0 && !emptyMessage && 
          <div className="text-center" id="productSummaryCardMessage2">
            <FontAwesomeIcon icon={faSearch} className="fs-2 mb-2" />
            <h1 className="font-weight-bold mb-2 fs-3">Oops, we didn't find anything</h1>
            <p className="mb-1 fs-1">Try selecting a different date</p>
          </div>}
        {!loading && errorMessage && !emptyMessage &&
          <p className="loadingText"><span>{errorMessage}</span></p>
        }
        {loading &&
          <>
          <div className="text-center pt-6">
            <Spinner type="grow" style={{ width: '3rem', height: '3rem', color: '#77b204' }} />
          </div>
         {loadingMessage ?
          <Typed
            strings={["Please wait, we’re building your file for you. Should take less time than decanting a nice red..."]}
            typeSpeed={10}
            className="loadingText"
          />
          : null}
        {loadingMessageLong ?
          <Typed
            strings={["Well, this is embarrassing. Maybe the server needs more coffee and less wine…? (We’re still waiting though - hopefully won’t be long now)"]}
            typeSpeed={10}
            className="loadingText"
          />
          : null}           
        </>
        }
        
      </CardBody>
    </Card>
  )
})

export default ProductsSummaryCard