import React from 'react'
import { CardDeck } from 'reactstrap'
import { Link } from "gatsby"

import StatsCard from '../statscard'

const CampaignStats = ({ selectedCampaign, campaignStats, loading }) => {
  return (
    <>
      {campaignStats && !loading ?
        <CardDeck className="mb-3 campaignStats">
          <Link to={`/campaigns/${selectedCampaign.value}/call`} className="fs-2 mb-0 d-inline" style={{ textDecoration: 'none' }}>
            <StatsCard
              title="Call attempts"
              statDescA="Total attempts"
              statValueA={campaignStats.totalCallAttempts}
              statDescB="Total contacts"
              statValueB={campaignStats.totalContacts}
              navIcon
            />
          </Link>
          <Link to={`/campaigns/${selectedCampaign.value}/contact`} className="fs-2 mb-0 d-inline" style={{ textDecoration: 'none' }}>
            <StatsCard
              title="Contacts"
              statDescA="Remaining"
              statValueA={campaignStats.remainingContacts}
              statDescB="Total contacts"
              statValueB={campaignStats.totalContacts}
              percentage={campaignStats.remainingPercent.toFixed(2)}
              percentageText='Remaining'
              navIcon
            />
          </Link>
          <StatsCard
            title="Customer conversion"
            statDescA="New"
            statValueA={campaignStats.newBuyers}
            statDescB="Total customer orders"
            statValueB={campaignStats.totalBuyers}
          />
          <StatsCard
            title="Overall revenue"
            statDescA="Total Product Revenue"
            statValueA={campaignStats.totalRevenue.toFixed(2)}
            statDescB="Freight (excl Campaign Adjustment)"
            statValueB={campaignStats.totalFreight}
            statUnit="$"
          />

        </CardDeck>
        : null}
    </>
  )
}

export default CampaignStats
