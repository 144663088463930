import React, { useEffect, useState, useRef } from 'react'
import { Helmet } from "react-helmet"
import DashboardLayout from '../../layouts/dashboardlayout'
import { navigate } from 'gatsby'
import { CheckToken, requestGetWithKey } from '../../helpers/utils'
import DisplayCampaign from '../../components/campaigns/campaignsSectionCard'
import DisplayGraph from '../../components/campaigns/displaygraph'
import CampaignStats from '../../components/campaigns/campaignstats'
import ProductsSummaryCard from '../../components/campaigns/productSummaryCard'
import moment from 'moment'
import { Button } from 'reactstrap'
import { DateRangePicker } from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import "react-datetime/css/react-datetime.css";

const Campaigns = ({ location }) => {
  const [campaignID, setCampaignID] = useState(null);
  const [CompleteCampaignList, setCompleteCampaignList] = useState([])
  const [activeCampaignList, setActiveCampaignList] = useState([])
  const [activeCampaigns, setActiveCampaigns] = useState(null)
  const [completedCampaigns, setCompletedCampaigns] = useState(null)
  const [selectedCampaign, setSelectedCampaign] = useState(null)
  const [campaignStats, setCampaignStats] = useState(null)
  const [xAxisGraphCalls, setXAxisGraphCalls] = useState(null)
  const [xAxisGraphRevenue, setXAxisGraphRevenue] = useState(null)
  const [combinedLabels, setCombinedLabels] = useState(null)
  const [totalOverallRevenue, setTotalOverallRevenue] = useState(null)
  const [listOfCampaigns, setListOfCampaigns] = useState(null)
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [todaysRevenue, setTodaysRevenue] = useState(null)
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [focusedInput, setFocusedInput] = useState(null);
  const productsSummaryRef = useRef();

  let graphXCalls = [];
  let graphYCalls = [];
  let graphXRevenue = []
  let graphYRevenue = []

  useEffect(() => {
    if (!CheckToken()) {
      navigate('/404')
    }

    const params = new URLSearchParams(location.search);
    const id = params.get("id");
    setCampaignID(id)
  }, [])

  useEffect(() => {
    const getCampaigns = [`api/zoho/campaigns`]
    requestGetWithKey(getCampaigns)
      .then(result => {
        let campaignList = result.message.data.map(d => {
          return { value: d.id, label: d.Campaign_Name, status: d.Status, StartDate: d.Start_Date, EndDate: d.End_Date, email: d.Owner.email, wineryName: d.Winery.name, ownerName: d.Owner.name }
        })
        setListOfCampaigns(campaignList)
        let activeData = campaignList.filter(function (el) { return el.status == 'Active' });
        let completeData = campaignList.filter(function (el) { return el.status == 'Complete' });
        if (activeData.length > 0) {
          let ActiveCamapaignSortData = activeData.sort(function (a, b) {
            var c = new Date(a.StartDate);
            var d = new Date(b.StartDate);
            return d - c;
          });
          setActiveCampaigns(ActiveCamapaignSortData)
        }
        if (completeData.length > 0) {
          let CompleteCamapaignSortData = completeData.sort(function (a, b) {
            var c = new Date(a.StartDate);
            var d = new Date(b.StartDate);
            return d - c;
          });
          setCompletedCampaigns(CompleteCamapaignSortData)
        }
        if (campaignID) {
          let filteredCampaign = campaignList.filter(campaignRecord => campaignRecord.value == campaignID)
          setSelectedCampaign(filteredCampaign[0])
        }
      })
      .catch(error => { })
    setLoading2(true)
    const getCampaign = [`api/zoho/campaigns?extend=true&status=Active&status=Complete`]
    requestGetWithKey(getCampaign)
      .then(result => {
        let res = result.message.data
        let tem_arr = res.filter(x => x.Status === 'Complete')
        let tem_arr2 = res.filter(x => x.Status === 'Active')
        setCompleteCampaignList(tem_arr)
        setActiveCampaignList(tem_arr2)
        setLoading2(false)
      })
      .catch(error => { setLoading2(false) })
  }, [campaignID])

  useEffect(() => {
    if (selectedCampaign && selectedCampaign?.value) {
      const start = startDate ? moment(startDate).format('YYYY-MM-DD') : null;
      const end = endDate ? moment(endDate).format('YYYY-MM-DD') : null;
      fetchCampaignData(start, end);
    }
  }, [selectedCampaign]);

  const fetchCampaignData = (start, end) => {
    setLoading(true);
    let url = `api/zoho/campaigns/${selectedCampaign.value}?extend=true`;
    if (start && end) {
      url += `&start=${start}&end=${end}`;
    }
    var getGraph = [url];
    requestGetWithKey(getGraph).then((d) => {
      let callsData = d.message.data ? d.message.data[0].Calls : null
      let revenueData = d.message.data ? d.message.data[0].Revenue: null
      let todaysRevenue = d.message.data ? d.message.data[0].Stats.todayGrandTotal : null
      let overallRevenue = d.message.data ? d.message.data[0].Stats.totalRevenue.toLocaleString() : null
      let graphDataCalls = d.message.data ? d.message.data[0].Stats : null
      setTotalOverallRevenue(overallRevenue)
      setTodaysRevenue(todaysRevenue)
      setCampaignStats(graphDataCalls)
      if (callsData?.length) {
        let currentDate = callsData[0]?.x ? moment(callsData[0]?.x, 'DD/MM/YYYY') : -1
        let stopDate = revenueData[revenueData.length - 1]?.x ? moment(revenueData[revenueData.length - 1]?.x, 'DD/MM/YYYY') : -1
        while (currentDate <= stopDate) {
          let searchDate = callsData.find(d => d.x === currentDate.format('DD/MM/YYYY'))
          if (searchDate) {
            graphXCalls.push(searchDate.x)
            graphYCalls.push(searchDate.y)
            currentDate = moment(currentDate).add(1, 'days');
          }
          else {
            graphXCalls.push(currentDate.format('DD/MM/YYYY'))
            graphYCalls.push(0)
            currentDate = moment(currentDate).add(1, 'days');
          }
        }
      }
      if (revenueData.length) {
        let currentDate = revenueData[0]?.x ? moment(revenueData[0]?.x, 'DD/MM/YYYY') : -1
        let stopDate = revenueData[revenueData.length - 1]?.x ? moment(revenueData[revenueData.length - 1]?.x, 'DD/MM/YYYY') : -1
        while (currentDate <= stopDate) {
          let searchDate = revenueData.find(d => d.x === currentDate.format('DD/MM/YYYY'))
          if (searchDate) {
            graphXRevenue.push(searchDate.x)
            graphYRevenue.push(searchDate.y)
            currentDate = moment(currentDate).add(1, 'days');
          }
          else {
            graphXRevenue.push(currentDate.format('DD/MM/YYYY'))
            graphYRevenue.push(0)
            currentDate = moment(currentDate).add(1, 'days');
          }
        }
      }
      setXAxisGraphCalls(graphYCalls)
      setXAxisGraphRevenue(graphYRevenue)
      setCombinedLabels(graphXCalls) //assumes calls and revenues already have the exact same x axis date range and length
      setLoading(false);
    })
  }

  const handleDateChange = ({ startDate, endDate }) => {
    if(startDate) {
      setStartDate(startDate);
    } else {
      setStartDate(null);
    }
    if(endDate) {
      setEndDate(endDate);
    } else {
      setEndDate(null);
    }
  };

  const handleButtonClick = () => {
    if (!startDate || !endDate) {
      console.error('Please select both start and end dates.');
      return;
    }
    const start = moment(startDate).format('YYYY-MM-DD');
    const end = moment(endDate).format('YYYY-MM-DD');
    fetchCampaignData(start, end);
    if (productsSummaryRef.current) {
      productsSummaryRef.current.fetchProductSummaryData(selectedCampaign, start, end);
    }
  };

  const handleClearClick = () => {
    setStartDate(null);
    setEndDate(null);
    fetchCampaignData();
    if (productsSummaryRef.current) {
      productsSummaryRef.current.fetchProductSummaryData(selectedCampaign, null, null);
    }
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Winery Portal - Campaigns</title>
      </Helmet>
      <DashboardLayout>
        <>
          <div className="row mb-n4">
            <div className="col-12">
              <div className="text-right mb-4">
                <div className="d-inline-block w-50 pr-4">
                  <DateRangePicker
                    startDate={startDate}
                    startDateId="tata-start-date"
                    endDate={endDate}
                    endDateId="tata-end-date"
                    onDatesChange={handleDateChange}
                    focusedInput={focusedInput}
                    isOutsideRange={() => false}
                    numberOfMonths={1}
                    onFocusChange={focusedInput => setFocusedInput(focusedInput)}
                    displayFormat="DD/MM/YYYY"
                    minimumNights={0}
                  />
                </div>
                <div className="d-inline-block">
                  <Button
                    id="campiagnDateRangeBtn"
                    className="ml-auto px-5 btn-vino btn-vino-primary"
                    transform="down-1 shrink-4"
                    style={{ width: 180 }}
                    onClick={() => handleButtonClick()}
                    disabled={!startDate || !endDate}
                  >
                    Search
                  </Button>
                </div>
                <div className="d-inline-block">
                  <Button
                    id="campiagnClearBtn"
                    className="ml-auto px-5 btn-vino btn-vino-primary"
                    transform="down-1 shrink-4"
                    style={{ width: 150 }}
                    onClick={() => handleClearClick()}
                    disabled={!startDate || !endDate}
                  >
                    Clear
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <DisplayGraph
            activeCampaigns={activeCampaigns}
            completedCampaigns={completedCampaigns}
            selectedCampaign={selectedCampaign}
            xAxisGraphCalls={xAxisGraphCalls}
            xAxisGraphRevenue={xAxisGraphRevenue}
            setSelectedCampaign={setSelectedCampaign}
            setXAxisGraphCalls={setXAxisGraphCalls}
            setXAxisGraphRevenue={setXAxisGraphRevenue}
            setCombinedLabels={setCombinedLabels}
            combinedLabels={combinedLabels}
            setTotalOverallRevenue={setTotalOverallRevenue}
            totalOverallRevenue={totalOverallRevenue}
            loading={loading}
            campaignId={campaignID}
            todaysRevenue={todaysRevenue}
          />
          {selectedCampaign ? <CampaignStats selectedCampaign={selectedCampaign} setCampaignStats={setCampaignStats}
          campaignStats={campaignStats} loading={loading} /> : null}
          <ProductsSummaryCard selectedCampaign={selectedCampaign} startDate={startDate} endDate={endDate} ref={productsSummaryRef} />
          <DisplayCampaign selectedCampaign={selectedCampaign} CompleteCampaignList={CompleteCampaignList} activeCampaignList={activeCampaignList} loading={loading2} />
        </>
      </DashboardLayout>
      </>
  )
}

export default Campaigns
