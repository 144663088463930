import React, { useEffect, useState } from 'react'
import { navigate } from '@reach/router';
import { Row, Col, Card, CardBody, CardTitle, CardText, Spinner } from 'reactstrap'
import { Line } from 'react-chartjs-2'
import Select from 'react-select'
import Typed from 'react-typed';
import { rgbaColor, randomMessage, typedDelay } from '../../helpers/utils'
import moment from 'moment'
import _ from 'lodash'

const DisplayGraph = ({ activeCampaigns, completedCampaigns, setSelectedCampaign, selectedCampaign, xAxisGraphCalls, xAxisGraphRevenue, combinedLabels, totalOverallRevenue, loading, campaignId, todaysRevenue }) => {
  const [loadingMessage, setLoadingMessage] = useState(null)

  useEffect(() => {
    setLoadingMessage(randomMessage())
  }, [])

  useEffect(() => {
    if (!selectedCampaign && !campaignId) {
      if (activeCampaigns) {
        return setSelectedCampaign(activeCampaigns[0])
      } else if (completedCampaigns) {
        return setSelectedCampaign(completedCampaigns[0])
      }
    }
  }, [activeCampaigns, completedCampaigns])

  if (activeCampaigns || completedCampaigns) {
    const changeGraph = (data) => {
      navigate(`?id=${data.value}`) //gatsby navigation ignores trailing slashes
      setSelectedCampaign(data)
    }
    const campaignsDropdownList = []
    if (activeCampaigns) {
      campaignsDropdownList.push({ label: 'Active Campaign', options: activeCampaigns })
    }
    if (completedCampaigns) {
      campaignsDropdownList.push({ label: 'Complete Campaign', options: completedCampaigns })
    }
    const config = {
      data(canvas) {
        const ctx = canvas.getContext('2d')
        const gradientFill = ctx.createLinearGradient(0, 0, 0, 250)
        gradientFill.addColorStop(0, 'rgba(255, 255, 255, 0.3)')
        gradientFill.addColorStop(1, 'rgba(255, 255, 255, 0)')
        return {
          type: 'line',
          labels: combinedLabels,
          datasets: [
            {
              label: "Calls",
              fill: false,
              borderWidth: 4,
              pointRadius: 4,
              data: xAxisGraphCalls,
              borderColor: rgbaColor('#fff', 0.8),
              backgroundColor: "#fff",
              yAxisID: 'y-axis-calls',
            },
            {
              label: "Revenue",
              fill: false,
              borderWidth: 4,
              pointRadius: 4,
              data: xAxisGraphRevenue,
              borderColor: rgbaColor("#ff6d21", 0.8),
              backgroundColor: "#ff6d21",
              yAxisID: 'y-axis-revenue',
            },
          ],
        }
      },
      options: {
        responsive: true,
        legend: {
          display: true,
          position: 'top',
          align: "start",
          labels: {
            usePointStyle: true,
            fontColor: rgbaColor('#fff', 1)
          }
        },
        tooltips: {
          mode: 'label',
          xPadding: 20,
          yPadding: 10,
          displayColors: false,
          callbacks: {
            label: function (tooltipItem, data) {
              return (tooltipItem.datasetIndex === 0 ? `${data.datasets[0].label} : ${tooltipItem.value}` : null)
            },
            afterLabel: function (tooltipItem, data) {
              return (tooltipItem.datasetIndex === 1 ? `${data.datasets[1].label}: $${parseFloat(tooltipItem.value).toFixed(2)}` : null)
            }
          }
        },

        hover: { mode: 'label' },
        stacked: false,
        scales: {
          xAxes: [
            {
              scaleLabel: {
                show: true,
                labelString: 'Month',
              },
              ticks: {
                fontColor: rgbaColor('#fff', 0.7),
                fontStyle: 600,
              },
              gridLines: {
                color: rgbaColor('#fff', 0.1),
                zeroLineColor: rgbaColor('#fff', 0.1),
                lineWidth: 1,
              },
            },
          ],
          yAxes: [
            {
              id: 'y-axis-calls',
              type: 'linear',
              position: 'left',
              ticks: {
                fontColor: rgbaColor('#fff', 0.7),
              },
              gridLines: {
                color: rgbaColor('#fff', 0.1),
              },
            },
            {
              id: 'y-axis-revenue',
              type: 'linear',
              position: 'right',
              ticks: {
                fontColor: rgbaColor('#FF8C00', 0.7),
              },
              gridLines: {
                color: rgbaColor('#FF8C00', 0.1),
              },
            },
          ],
        },
      },
    }
    return (
      <>
        <Card className="mb-3">
          <CardBody className="rounded-soft bg-gradient">
            <Row className="text-white align-items-center no-gutters">
              <Col className="col-xs-12 graphLeft">
                <div className="camiagn_Text">
                  <h3 className="text-white">{selectedCampaign?.label}</h3>
                  <p className="text-white">
                    {moment(selectedCampaign?.StartDate).format('DD/MM/YYYY')} - {''}
                    <span className="text-white">{moment(selectedCampaign?.EndDate).format('DD/MM/YYYY')}</span>
                  </p>
                  <p>{selectedCampaign?.status}</p>
                </div>
              </Col>
              <Col xs="auto" className="d-sm-block col-xs-12">
                <Select
                  styles={{
                    container: provided => ({ ...provided, width: `${8 * selectedCampaign?.label.length + 42}px` }),
                  }}
                  options={campaignsDropdownList}
                  value={selectedCampaign}
                  onChange={e => changeGraph(e)}
                  classNamePrefix="react-select"
                  id="searchCampaign"
                  className="selectCampaign"
                />
              </Col>
            </Row>
            <p className="OverallRevenue text-white">Today ${todaysRevenue?.toFixed(2)}</p>
            {loading ? (
              <div className="text-center">
                <Spinner type="grow" style={{ width: '3rem', height: '3rem', color: '#77b204' }} />
              </div>
            ) : (
              <Line data={config.data} options={config.options} width={1618} height={350} />
            )}
          </CardBody>
        </Card>
      </>
    )
  } else {
    return (
      <Card id="exportContacts">
        <CardBody className="contactBody">
          <CardTitle tag="h5" className="cardCount cardLight">
            {loadingMessage}
          </CardTitle>
          {activeCampaigns === null && completedCampaigns === null ? (
            <Typed
              id='noExportOrders'
              strings={[`locating all campaigns${typedDelay}.${typedDelay}.${typedDelay}.${typedDelay}.${typedDelay}.`]}
              className="cardSubText cardLight"
              showCursor={false}
              typeSpeed={20}
              loop
            />
          ) : (
            <CardText tag="p" className="cardSubText cardLight" id="noExportContacts">
              No campaigns to view, yet.
            </CardText>
          )}
        </CardBody>
      </Card>
    )
  }
}

export default DisplayGraph
