import React from 'react'
import { Card, CardBody } from 'reactstrap'
import CountUp from 'react-countup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'

const StatsCard = ({ title, statDescA, statDescB, statValueA, statValueB, percentage, statUnit, percentageText, navIcon = false }) => {
  return (
    <Card>
      <CardBody>
        <h1 className="text-black font-weight-bold fs-2 mb-0 d-inline" id="StatTitle">
          {title}
        </h1>
        {navIcon && (
          <div className="campaignStatsLink">
            <FontAwesomeIcon icon={faChevronRight} className="fs--2" />
          </div>
        )}
        <p className="fs--1 text-black mb-2">
          {statDescA} / {statDescB}
        </p>
        <div className="row">
          <div className="col-9">
            <CountUp
              end={statValueA}
              duration={2}
              prefix={statUnit}
              className="fs-2 text-primary font-weight-bold mb-0 d-inline"
              decimals={statUnit == '$' ? 2 : 0}
            />{' '}
            /&nbsp;
            <CountUp
              end={statValueB}
              duration={2}
              prefix={statUnit}
              className="fs-2 text-primary font-weight-bold mb-0 d-inline"
              decimals={statUnit == '$' ? 2 : 0}
            />
          </div>
          <div className="col-3 text-right">
            {percentage && (
              <p
                className="fs-0 font-weight-bold mb-0 d-inline"
                style={{ position: 'absolute', bottom: '5px', right: '16px' }}
              >
                {percentage}% {percentageText}
              </p>
            )}
          </div>
        </div>
      </CardBody>
    </Card>
  )
}

export default StatsCard
