import React from 'react'
import { CardDeck, Card, CardHeader, CardFooter, CardBody, ListGroup, ListGroupItem, Spinner } from 'reactstrap'
import moment from 'moment'

const DisplayCampaign = ({ selectedCampaign, CompleteCampaignList, activeCampaignList, loading }) => {
  return (
    selectedCampaign &&
    <CardDeck>
      <Card>
        <CardHeader id="activeCard">Active Campaigns <span style={{fontSize: "12px"}}>(Total Product Revenue / Freight (excl Campaign Adjustment))</span></CardHeader>
        <CardBody className="campaigns">
        {loading ? <div className="text-center mt-6"><Spinner type="grow" style={{ width: '3rem', height: '3rem', color: '#77b204' }} /></div> : 
          <ListGroup>
            {activeCampaignList?.length ? (
                activeCampaignList.map((data, index) => {

                return (
                    <ListGroupItem key={data.id}>
                      <div style={{ textAlign: "left" }}>
                        <p>{data.Campaign_Name}</p>
                      </div>
                      <div>
                      <p>{data.Overall_Revenue ? '$' + data.Overall_Revenue.toLocaleString("en-AU") : '-'} / {data.Overall_Shipping ? '$' + data.Overall_Shipping.toLocaleString("en-AU") : '-'}</p>
                        <p>
                          {moment(data.Start_Date).format('DD/MM/YYYY')} - {''}
                          <span>{moment(data.End_Date).format('DD/MM/YYYY')}</span>
                        </p>
                      </div>
                    </ListGroupItem>
                )
              })
            ) : (
              <div className="no-campaign">
                <p className="headText">Keep 'em coming.</p>
                <p className="noCampaignSubText">Active Campigns will be displayed here.</p>
                <p className="noCampaignSubText">Check back soon.</p>
              </div>
            )}
          </ListGroup>}
        </CardBody>
        <CardFooter />
      </Card>
      <Card>
        <CardHeader id="completeCard">Previous Campaigns <span style={{fontSize: "12px"}}>(Total Product Revenue / Freight (excl Campaign Adjustment))</span></CardHeader>
        <CardBody className="campaigns">
        {loading ? <div className="text-center mt-6"><Spinner type="grow" style={{ width: '3rem', height: '3rem', color: '#77b204' }} /></div> : 
          <ListGroup>
            {CompleteCampaignList?.length ? (
                CompleteCampaignList.map((data, index) => {
                return (
                    <ListGroupItem key={data.id}>
                      <div>
                        <p>{data.Campaign_Name}</p>
                      </div>
                      <div>
                      <p>{data.Overall_Revenue ? '$' + data.Overall_Revenue.toLocaleString("en-AU") : '-'} / {data.Overall_Shipping ? '$' + data.Overall_Shipping.toLocaleString("en-AU") : '-'}</p>
                        <p>
                          {moment(data.Start_Date).format('DD/MM/YYYY')} - {''}
                          <span>{moment(data.End_Date).format('DD/MM/YYYY')}</span>
                        </p>
                      </div>
                    </ListGroupItem>
                )
              })
            ) : (
              <div className="no-campaign">
                <p className="headText">Keep 'em coming.</p>
                <p className="noCampaignSubText">Completed Campigns will be displayed here.</p>
                <p className="noCampaignSubText">Check back soon.</p>
              </div>
            )}
          </ListGroup>}
        </CardBody>
        <CardFooter />
      </Card>
    </CardDeck>
  )
}

export default DisplayCampaign
